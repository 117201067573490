/* eslint-disable jsx-a11y/alt-text */
import Image from 'next/image'
import Skeleton from 'react-loading-skeleton'
import { useDrawer, useLoading, useOverlay } from '../../contexts'
import { useRouter } from 'next/router'
import routes from 'constants/routes'

const LoadingSkeleton = () => (
  <Skeleton className='absolute left-0 top-0 h-full w-full' />
)

type Props = {
  className?: string
  light?: boolean
  clickable?: boolean
}

export const Logo = ({ light, className, clickable = true }: Props) => {
  const { setIsOpen: setIsDrawerOpen } = useDrawer()
  const { setIsOpen: setIsOverlayOpen } = useOverlay()
  const { isLogoLoaded, setIsLogoLoaded } = useLoading()
  const router = useRouter()

  const imgProps = {
    alt: 'FairArt Logo',
    fill: true,
    quality: 100,
    className: 'object-contain object-center',
    sizes: '20vw',
    onLoad: () => setIsLogoLoaded(true),
  } as const

  const content = (
    <>
      {light ? (
        <div className='relative flex h-[26px] w-[106px] items-center'>
          {!isLogoLoaded && <LoadingSkeleton />}

          <Image src='/files/logo-fairart-light.png' {...imgProps} />
        </div>
      ) : (
        <>
          <div className='relative hidden h-[26px] w-[106px] items-center lg:flex'>
            {!isLogoLoaded && <LoadingSkeleton />}

            <Image
              src={`/files/logo-fairart-${light ? 'light' : 'dark'}.png`}
              {...imgProps}
            />
          </div>
          <div className='relative flex h-[24px] w-[114px] items-center lg:hidden'>
            {!isLogoLoaded && <LoadingSkeleton />}

            <Image src='/files/logo-fairart-dark.png' {...imgProps} />
          </div>
        </>
      )}
    </>
  )

  return (
    <div className={`flex ${className}`}>
      {clickable ? (
        <span
          className='flex cursor-pointer items-center justify-start'
          onClick={() => {
            const isReviewPage = router.asPath.startsWith(
              routes.bidOrBuy.reviewBid
            )
            if (isReviewPage) {
              const confirmLeave = window.confirm(
                'You have unsaved changes. Are you sure you want to leave?'
              )
              if (confirmLeave) {
                setIsDrawerOpen(false)
                setIsOverlayOpen(false)
                router.push('/')
              }
            } else {
              setIsDrawerOpen(false)
              setIsOverlayOpen(false)
              router.push('/')
            }
          }}
        >
          {content}
        </span>
      ) : (
        <div className='flex items-center justify-start'>{content}</div>
      )}
    </div>
  )
}
