import { ChildrenWithProps } from 'components/abstract/ChildrenWithProps'
import { useRouter } from 'next/router'
import React from 'react'

type Props = {
  className?: string
  children?: React.ReactNode
  vertical?: boolean
  listClassName?: string
}

export const Nav = ({
  vertical,
  className,
  children,
  listClassName,
}: Props) => {
  const router = useRouter()

  const onChildClone = (child: React.ReactElement) => {
    return React.cloneElement(child, {
      active:
        child.props.href !== '/sell' && child.props.exact
          ? router.asPath === child.props.href
          : router.asPath.includes(child.props.href) &&
            !router.asPath.includes('/my-account/'),
      activeTag:
        child.props.href !== '/sell' &&
        router.asPath.includes(child.props.href) &&
        router.asPath.includes('/my-account/'),
      hasArrowIcon: vertical && child.props.href,
    })
  }

  return (
    <nav className={className}>
      <ul
        className={`flex
          ${vertical ? 'flex-col space-y-3' : 'items-center space-x-2'}
          ${listClassName}
        `}
      >
        <ChildrenWithProps
          props={{ hasArrowIcon: vertical }}
          onChildClone={onChildClone}
        >
          {children}
        </ChildrenWithProps>
      </ul>
    </nav>
  )
}
