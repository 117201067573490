import { AuthActions } from 'components/common/AuthActions'
import { Icon } from 'components/common/Icon'
import { Logo } from 'components/common/Logo'
import { MobileMenu } from 'components/common/MobileMenu'
import { Nav } from 'components/nav/Nav'
import { NavItem } from 'components/nav/NavItem'
import { NotificationsPopup } from 'components/notifications/NotificationsPopup'
import { useFeatureFlags } from 'hooks'
import { useUserNotifications } from 'hooks/useUserNotifications'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { ReactNode, Suspense, lazy } from 'react'
import routes from '../../constants/routes'
import { useAuth, useDrawer } from '../../contexts'
import { TooltipMenu } from 'components/common/TooltipMenu'

const SellPopupMenu = lazy(() =>
  import('components/common/SellPopupMenu').then(m => ({
    default: m.SellPopupMenu,
  }))
)
const SellMobileNavMenu = lazy(() =>
  import('components/common/SellPopupMenu').then(m => ({
    default: m.SellMobileNavMenu,
  }))
)
const MyAccountMobileNavMenu = lazy(() =>
  import('components/common/MyAccountPopupMenu').then(m => ({
    default: m.MyAccountMobileNavMenu,
  }))
)
const HIWPopupMenu = lazy(() =>
  import('components/common/HIWPopupMenu').then(m => ({
    default: m.HIWPopupMenu,
  }))
)
const HIWMobileNavMenu = lazy(() =>
  import('components/common/HIWPopupMenu').then(m => ({
    default: m.HIWMobileNavMenu,
  }))
)

type Props = {
  className?: string
  vertical?: boolean
}

export const NavMenu = ({ vertical, className }: Props) => {
  const { isAuthenticated } = useAuth()
  const { t } = useTranslation('common')
  const { setConfig } = useDrawer()
  const {
    data: { notViewedCount = 0 },
  } = useUserNotifications()

  const { featureFlags } = useFeatureFlags()

  const labels = {
    home: t('home'),
    artworks: t('artworks'),
    artists: t('artists'),
    sell_an_item: t('sell_an_item'),
    how_it_works: t('how_it_works'),
    sell: t('sell'),
    buying: t('buying'),
    orders_and_bids: t('orders_and_bids'),
    selling: t('selling'),
    sales_and_asks: t('sales_and_asks'),
    settings: t('settings'),
    my_account: t('my_account'),
    calendar: t('calendar'),
    release_calendar: t('release_calendar'),
    notifications: t('notifications'),
  }

  const setMobileMenu = (title: string, body: ReactNode) => {
    setConfig({
      heading: (
        <div className='flex items-center space-x-[10px]'>
          <button
            type='button'
            onClick={() => {
              setConfig({
                heading: <Logo />,
                body: <MobileMenu actions={<AuthActions isMobile />} />,
              })
            }}
          >
            <Icon name='back-arrow' className='text-cool-500' size={20} />
          </button>
          <h5 className='font-body text-lg font-medium'>{title}</h5>
        </div>
      ),
      body,
      hasIcon: true,
    })
  }

  return (
    <Nav vertical={vertical} className={className} listClassName='!space-x-0'>
      <NavItem mobile href='/' exact className='lg:hidden'>
        {labels.home}
      </NavItem>

      <NavItem href={routes.artworks}>{labels.artworks}</NavItem>

      <NavItem href={routes.artists}>{labels.artists}</NavItem>

      <NavItem className='hidden lg:block' href={routes.releaseCalendar.base}>
        <span>{labels.calendar}</span>
        <Icon
          name='filled-new'
          filled
          size={24}
          className='ml-1.5 text-purple-500'
        />
      </NavItem>

      <NavItem className='lg:hidden' href={routes.releaseCalendar.base}>
        <div className='flex items-center'>
          <span>{labels.release_calendar}</span>
          <Icon
            name='filled-new'
            filled
            size={24}
            className='ml-1.5 text-purple-500'
          />
        </div>
      </NavItem>

      {featureFlags.notification && isAuthenticated && (
        <NavItem
          mobile
          className='flex justify-between lg:hidden'
          href={routes.notifications}
        >
          <span>{labels.notifications}</span>

          {Boolean(notViewedCount) && (
            <div className='rounded-full bg-danger-dark px-2 py-0.5 text-xs font-medium text-cool-50'>
              {t('x_new', { count: notViewedCount })}
            </div>
          )}
        </NavItem>
      )}

      <NavItem
        mobile
        className='lg:hidden'
        onClick={() => {
          setMobileMenu(
            labels.sell_an_item,
            <Suspense>
              <SellMobileNavMenu />
            </Suspense>
          )
        }}
      >
        {labels.sell_an_item}
      </NavItem>

      <NavItem
        mobile
        className='lg:hidden'
        onClick={() => {
          setMobileMenu(
            labels.how_it_works,
            <Suspense
              fallback={<div className='h-full w-full bg-cool-150'></div>}
            >
              <HIWMobileNavMenu />
            </Suspense>
          )
        }}
      >
        {labels.how_it_works}
      </NavItem>

      <NavItem className='hidden lg:block'>
        <TooltipMenu
          content={
            <Suspense>
              <HIWPopupMenu />
            </Suspense>
          }
          contentClassName='mt-1 !z-[999999] max-w-[450px] '
          placement='bottom-left'
          className='flex cursor-pointer space-x-2'
        >
          {labels.how_it_works}
        </TooltipMenu>
      </NavItem>

      <NavItem className='hidden lg:block'>
        <TooltipMenu
          content={
            <Suspense
              fallback={<div className='h-full w-full bg-cool-150'></div>}
            >
              <SellPopupMenu />
            </Suspense>
          }
          placement='bottom-left'
          className='flex cursor-pointer space-x-2'
          contentClassName='mt-1 max-w-[250px] !z-[999999]'
        >
          {labels.sell}
        </TooltipMenu>
      </NavItem>

      {featureFlags.notification && isAuthenticated && (
        <div className='hidden pl-4 lg:block'>
          <Link href={routes.notifications}>
            <NotificationsPopup />
          </Link>
        </div>
      )}

      {isAuthenticated && (
        <NavItem
          mobile
          className='lg:hidden'
          onClick={() =>
            setMobileMenu(
              labels.my_account,
              <Suspense
                fallback={<div className='h-full w-full bg-cool-150'></div>}
              >
                <MyAccountMobileNavMenu />
              </Suspense>
            )
          }
        >
          {labels.my_account}
        </NavItem>
      )}
    </Nav>
  )
}
