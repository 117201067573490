/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Icon } from 'components/common/Icon'
import routes from 'constants/routes'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef } from 'react'
import { ContainerElement } from 'types'
import { useOverlay, useSearch } from '../../contexts'
import { detect } from 'detect-browser'

const SearchResults = dynamic(
  () =>
    import('components/common/SearchResults').then(m => ({
      default: m.SearchResults,
    })),
  { ssr: false }
)

const HeaderSearchBox = dynamic(
  () =>
    import('components/common/HeaderSearchBox').then(m => ({
      default: m.HeaderSearchBox,
    })),
  { ssr: false }
)

export const TriggerSearch = ({ children, className }: ContainerElement) => {
  const { setIsOpen, setConfig } = useOverlay()
  const { setQuery } = useSearch()
  const router = useRouter()

  const ref = useRef<HTMLDivElement | null>(null)

  const onClick = useCallback(
    (query?: string) => {
      if (!setConfig || !setIsOpen) return

      const browser = detect()

      setConfig({
        heading: (
          <div className='flex items-center space-x-4'>
            <Icon name='search' size={24} className='text-cool-500' />

            <HeaderSearchBox query={query} />
          </div>
        ),
        body: (
          <SearchResults className='search-results max-h-full w-full overflow-y-auto sm:h-fit' />
        ),
        headingClassName: 'sm:!max-w-[600px]',
        bodyClassName: 'sm:py-4',
        withInstantSearch: true,
      })
      setIsOpen(true)

      let queryBeforeInteractive = ''

      const onWindowKeyDown = (e: KeyboardEvent) => {
        const regex = new RegExp(
          /^[a-z0-9!"#$%&'()*+,./:;<=>?@[\] ^_`{|}~-]*$/i
        )

        if (e.key === 'Backspace') {
          queryBeforeInteractive = queryBeforeInteractive.slice(
            0,
            queryBeforeInteractive.length - 1
          )
          setQuery(queryBeforeInteractive)
          return
        }

        if (e.key.length !== 1 || !regex.test(e.key) || e.ctrlKey) {
          return
        }

        queryBeforeInteractive += e.key
        setQuery(queryBeforeInteractive)
      }

      const onWindowPaste = (e: ClipboardEvent) => {
        queryBeforeInteractive += e.clipboardData?.getData('text/plain') || ''

        if (
          browser?.os === 'Mac OS' &&
          queryBeforeInteractive.startsWith('v')
        ) {
          setQuery(
            queryBeforeInteractive.slice(1, queryBeforeInteractive.length)
          )
        } else {
          setQuery(queryBeforeInteractive)
        }
      }

      window.addEventListener('keydown', onWindowKeyDown)
      // // @ts-ignore
      window.addEventListener('paste', onWindowPaste)

      // some fcked up way for autofocus to work on fcking ios
      if (ref.current) {
        const fakeInput = document.createElement('input')
        fakeInput.setAttribute('type', 'text')
        fakeInput.style.position = 'absolute'
        fakeInput.style.opacity = '0'
        fakeInput.style.height = '0'
        fakeInput.style.fontSize = '16px'
        ref.current.appendChild(fakeInput)
        fakeInput.focus({ preventScroll: true })
      }

      setTimeout(() => {
        const searchBox = document.getElementById('header-search-box')
        searchBox?.focus({ preventScroll: true })
        searchBox?.click()
        // document.body.removeChild(fakeInput)
        window.removeEventListener('keydown', onWindowKeyDown)
        // @ts-ignore
        window.removeEventListener('paste', onWindowPaste)
      }, 1000)

      return () => {
        window.removeEventListener('keydown', onWindowKeyDown)
        // @ts-ignore
        window.removeEventListener('paste', onWindowPaste)
      }
    },

    [setConfig, setIsOpen, setQuery]
  )

  useEffect(() => {
    const { pathname } = router
    const { action } = router.query

    if (pathname !== routes.homepage || action !== 'show_form' || !onClick)
      return

    router.replace(routes.homepage).then(() => {
      onClick('no results')
    })
  }, [router, onClick])

  return (
    <div
      ref={ref}
      role='button'
      onClick={() => onClick()}
      className={`search-results cursor-pointer [&_*]:pointer-events-none ${className}`}
      aria-label='Search by artwork or artist'
    >
      {children}
    </div>
  )
}
