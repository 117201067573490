import classNames from 'classnames'
import { Children, cloneElement, isValidElement } from 'react'

type Props<T> = {
  children: React.ReactNode
  only?: React.ReactNode
  props: T & { className?: string }
  onChildClone?: (child: React.ReactElement) => React.ReactElement
}

export const ChildrenWithProps = <T,>({
  onChildClone,
  children,
  only,
  props,
}: Props<T>) => {
  const childrenWithProps = Children.map(children, item => {
    if (isValidElement(item)) {
      let child = item

      if (onChildClone) {
        child = onChildClone(child)
      }

      const childProps = {
        ...child.props,
        ...props,
        className: classNames(child.props.className, props.className),
      }

      if (only) {
        if (child.type === only) {
          return cloneElement(child, childProps)
        }
        return child
      }
      return cloneElement(child, childProps)
    }
    return item
  })
  return <>{childrenWithProps}</>
}
