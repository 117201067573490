import { INotification } from '@/types'
import { API_BASEPATH, Err } from 'api'
import { NamedEntity, StrapiPagination } from 'types'

export enum NotificationType {
  'ORDER_CONFIRMATION' = 'ORDER_CONFIRMATION',
  'SALE_CONFIRMATION' = 'SALE_CONFIRMATION',
  'SALE_CONFIRMATION_PASS_AUTH' = 'SALE_CONFIRMATION_PASS_AUTH',
  'SALE_BY_ANOTHER_SELLER' = 'SALE_BY_ANOTHER_SELLER',
  'ORDER_BY_ANOTHER_BUYER' = 'ORDER_BY_ANOTHER_BUYER',
  'NEW_SALE_FOLLOW_ARTIST' = 'NEW_SALE_FOLLOW_ARTIST',
  'NEW_SALE_SAVE_ARTWORK' = 'NEW_SALE_SAVE_ARTWORK',
  'NEW_HIGHEST_BID_SELLER' = 'NEW_HIGHEST_BID_SELLER',
  'NEW_HIGHEST_BID_BUYER' = 'NEW_HIGHEST_BID_BUYER',
  'NEW_HIGHEST_BID_BUYER_FOLLOW' = 'NEW_HIGHEST_BID_BUYER_FOLLOW',
  'NEW_LOWEST_ASK_BUYER' = 'NEW_LOWEST_ASK_BUYER',
  'NEW_LOWEST_ASK_SELLER' = 'NEW_LOWEST_ASK_SELLER',
  'NEW_LOWEST_ASK_SELLER_FOLLOW' = 'NEW_LOWEST_ASK_SELLER_FOLLOW',
  'ASK_EXPIRED' = 'ASK_EXPIRED',
  'BID_EXPIRED' = 'BID_EXPIRED',
  'ASK_EXPIRED_7days' = 'ASK_EXPIRED_7days',
  'BID_EXPIRED_7days' = 'BID_EXPIRED_7days',
  'ASK_EXPIRED_1day' = 'ASK_EXPIRED_1day',
  'BID_EXPIRED_1day' = 'BID_EXPIRED_1day',
}

export type Notification = {
  id: number
  type: NotificationType
  artwork?: {
    id: number
    name?: string
    images?: { id: number; url?: string }[]
    artists?: NamedEntity[]
  }
} & Omit<INotification['attributes'], 'type' | 'artwork'>

type GetNotificationsRes = {
  data?: INotification[]
  meta?: { pagination: StrapiPagination }
  error?: Err
}

export type GetUserNotificationsPayload = {
  page?: number
  pageSize?: number
}

type GetUserNotificationsRes = {
  results?: Notification[]
  pagination?: StrapiPagination
  error?: Err
}

type ViewNotificationsPayload = {
  notificationIds: number[]
  view: boolean
}

type ViewNotificationsRes = {
  error?: Err
  success?: boolean
}

export const getNotifications = async (query?: string) => {
  const res = await fetch(`${API_BASEPATH}/api/notifications?${query}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await res.json()
  return data as GetNotificationsRes
}

export const getUserNotifications = async (
  payload: GetUserNotificationsPayload = {}
) => {
  const res = await fetch(
    `${API_BASEPATH}/api/notifications/user-notifications`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(payload),
    }
  )
  const data = await res.json()
  return data as GetUserNotificationsRes
}

export const viewNotifications = async (payload: ViewNotificationsPayload) => {
  const res = await fetch(`${API_BASEPATH}/api/notifications/view-un-view`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(payload),
  })
  const data = await res.json()
  return data as ViewNotificationsRes
}
