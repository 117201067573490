import cx from 'classnames'
import { Button } from 'components/common/Button'
import { Input } from 'components/common/Input'
import { StaticIcon } from 'components/common/StaticIcon'
import { TriggerSearch } from 'components/common/TriggerSearch'
import { useWaysTo } from 'components/common/WaysTo'
import useTranslation from 'next-translate/useTranslation'
import { useDrawer } from '../../contexts'

export type ActionsState =
  | 'auth'
  | 'faq-buy'
  | 'faq-sell'
  | 'ways-to-buy'
  | 'ways-to-sell'

type Props = {
  state?: ActionsState
  isMobile?: boolean
  offWhite?: boolean
}

export const Actions = ({ state = 'auth', isMobile = false }: Props) => {
  const { t } = useTranslation()
  const { setIsOpen } = useDrawer()
  const setWaysTo = useWaysTo()

  return (
    <>
      {state.includes('faq') && (
        <div className='flex items-center justify-end'>
          <Button
            pill
            type='link'
            href={
              state === 'faq-buy'
                ? 'https://intercom.help/fairart/en/collections/3557957-buying-on-fairart'
                : 'https://intercom.help/fairart/en/collections/3557937-selling-on-fairart'
            }
            target='_blank'
            style='outline'
            className='font-medium text-cool-700'
            onClick={() => setIsOpen(false)}
            size={isMobile ? 'xl' : 'md'}
          >
            {t('common:faq')}
          </Button>
        </div>
      )}

      {state.includes('ways-to') && (
        <button
          className='text-sm text-secondary-default underline'
          onClick={() => setWaysTo(state === 'ways-to-buy' ? 'buy' : 'sell')}
        >
          {state === 'ways-to-buy'
            ? t('common:how_buying_works')
            : t('common:how_selling_works')}
        </button>
      )}

      {state === 'auth' && (
        <>
          <div className='ml-8 mr-2 hidden w-full items-center justify-end lg:flex'>
            <TriggerSearch className='w-full'>
              <Input
                type='search'
                placeholder='Search by artwork or artist'
                pill
                iconSize={16}
                iconClassName='text-primary-dark'
                inputClassName={cx(
                  'cursor-pointer border-transparent bg-warm-100 placeholder:text-cool-600 hover:bg-warm-50 hover:border-primary-default'
                )}
                data-e2e='trigger-search-desktop'
                onKeyDown={e => e.preventDefault()}
              />
            </TriggerSearch>
          </div>

          <div className='flex items-center justify-end  lg:hidden'>
            <TriggerSearch>
              <button
                type='button'
                className='flex items-center'
                aria-label={t('common:cta_search')}
                data-e2e='trigger-search-mobile'
              >
                <StaticIcon name='search' size={24} className='text-cool-500' />
              </button>
            </TriggerSearch>
          </div>
        </>
      )}
    </>
  )
}
