import { Notification, getUserNotifications } from 'api/notifications'
import { useAuth } from 'contexts'
import { useQuery } from 'react-query'

type UserNotificationsData = {
  notifications?: Notification[]
  notViewedCount?: number
}

export const useUserNotifications = () => {
  const { user } = useAuth()

  const query = useQuery<UserNotificationsData>(
    ['notifications', user?.id],
    async () => {
      const { results = [] } = await getUserNotifications({
        page: 1,
        pageSize: 100,
      })
      if (!results.length) return { notifications: [], notViewedCount: 0 }
      return {
        notifications: results,
        notViewedCount: results.reduce(
          (prev, currNotif) => prev + (currNotif.viewed ? 0 : 1),
          0
        ),
      }
    },
    { enabled: Boolean(user) }
  )

  return { ...query, data: query?.data || {} }
}
