import { Icon } from 'components/common/Icon'
import { Tag } from 'components/common/Tag'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { AnchorHTMLAttributes } from 'react'
import { useDrawer } from '../../contexts'

type Props = {
  className?: string
  textClassName?: string
  href?: string
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target']
  children?: React.ReactNode
  active?: boolean
  activeTag?: boolean
  hasArrowIcon?: boolean
  mobile?: boolean
  onClick?: () => void
  exact?: boolean
}

export const NavItem = ({
  className,
  textClassName,
  href,
  target = '_self',
  children,
  active,
  activeTag,
  hasArrowIcon,
  mobile,
  onClick,
}: Props) => {
  const { setIsOpen } = useDrawer()
  const router = useRouter()

  const getTagIcon = () => {
    if (router.pathname.includes('/my-account/buying')) {
      return 'cart'
    }
    if (router.pathname.includes('/my-account/selling')) {
      return 'cash-money'
    }
    if (router.pathname.includes('/my-account/settings')) {
      return 'settings'
    }
  }

  const aClassName = `w-full flex h-10 items-center justify-between whitespace-nowrap rounded bg-transparent transition-colors duration-200 hover:bg-cool-900 hover:bg-opacity-3 hover:text-cool-800
    ${
      active &&
      '!font-medium !text-cool-900 [&_span]:!font-medium [&_span]:!text-cool-900'
    }
    ${
      activeTag &&
      '!font-medium !text-cool-900 [&_span]:!font-medium [&_span]:!text-cool-900'
    }
    ${
      hasArrowIcon
        ? 'px-0 text-lg font-normal text-cool-800'
        : 'px-4 text-sm font-medium text-cool-600'
    }
    ${textClassName}`

  const content = (
    <>
      {children}
      {hasArrowIcon && !href ? <Icon name='arrow-chevron-forward' /> : null}
    </>
  )

  if (activeTag && !mobile) {
    if (!href)
      return (
        <Tag className='font-medium' icon={getTagIcon()} iconSize={16}>
          {children}
        </Tag>
      )

    return (
      <li className={className}>
        <Link href={href} prefetch={false} target={target}>
          <Tag className='font-medium' icon={getTagIcon()} iconSize={16}>
            {children}
          </Tag>
        </Link>
      </li>
    )
  }

  if (!href)
    return (
      <li className={className}>
        {onClick ? (
          <button type='button' onClick={onClick} className={aClassName}>
            {content}
          </button>
        ) : (
          <span className={aClassName}>{content}</span>
        )}
      </li>
    )

  return (
    <li className={className}>
      <Link
        href={href}
        prefetch={false}
        className={aClassName}
        onClick={() => hasArrowIcon && setIsOpen(false)}
        target={target}
      >
        {content}
      </Link>
    </li>
  )
}
