import { Notification, viewNotifications } from 'api/notifications'
import classNames from 'classnames'
import { Icon } from 'components/common/Icon'
import { TooltipMenu } from 'components/common/TooltipMenu'
import { NotificationComponent } from 'components/notifications/Notification'
import routes from 'constants/routes'
import { useUserNotifications } from 'hooks/useUserNotifications'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useEffect, useMemo, useRef } from 'react'
import { useQueryClient } from 'react-query'

const NotificationsContent = ({
  notifications,
}: {
  notifications: Notification[]
}) => {
  const { t } = useTranslation('notifications')
  const queryClient = useQueryClient()

  const containerRef = useRef<HTMLDivElement | null>(null)
  const shouldRefetch = useRef(false)

  const observer = useMemo(() => {
    return new IntersectionObserver(async entries => {
      const [element] = entries

      if (!element) return

      if (!element.isIntersecting) {
        if (shouldRefetch.current) {
          await queryClient.refetchQueries(['notifications'])
          shouldRefetch.current = false
        }

        return
      }

      const notificationIds = notifications
        .filter(n => !n.viewed)
        .map(n => n.id)

      if (!notificationIds.length) return

      try {
        const { error, success } = await viewNotifications({
          notificationIds,
          view: true,
        })

        if (!success) throw new Error('An error occured')

        if (error) throw new Error(error.message)

        shouldRefetch.current = true
      } catch (err) {
        console.error(err)
      }
    })
  }, [queryClient, notifications])

  const attachObserver = (ref: HTMLDivElement | null) => {
    if (!ref) return
    observer.observe(ref)
  }

  useEffect(() => {
    if (containerRef.current) {
      attachObserver(containerRef.current)
    }

    return () => {
      observer.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observer, containerRef])

  return (
    <div ref={containerRef}>
      <ol>
        {notifications.map((notification, index) => (
          <li key={`${notification.type}@${index}`}>
            <NotificationComponent
              {...notification}
              size='sm'
              classNames={{
                imageContainer: 'p-2.5 bg-warm-50',
                textContainer: 'px-3 py-2.5',
                root: classNames(
                  index !== 0 && 'border-t-2 border-primary-light'
                ),
              }}
            />
          </li>
        ))}
      </ol>

      <div className='flex items-center justify-center border-t-2 border-primary-light py-3'>
        <Link
          href={routes.notifications}
          className='text-xs font-medium text-purple-500'
        >
          {t('common:view_all')}
        </Link>
      </div>
    </div>
  )
}

export const NotificationsPopup = () => {
  const { t } = useTranslation('notifications')

  const {
    data: { notifications = [], notViewedCount = 0 },
  } = useUserNotifications()

  return (
    <TooltipMenu
      content={
        <NotificationsContent notifications={notifications.slice(0, 6)} />
      }
      placement='bottom-left'
      className='rounded-full border border-cool-300 p-2 transition-all hover:bg-warm-100'
      contentClassName='mt-1 max-w-[17.5rem] min-w-[17.5rem] !z-[999999] !p-0 overflow-hidden '
    >
      <span className='sr-only'>{t('common:view_notifications')}</span>

      <div className='relative'>
        {notViewedCount ? (
          <>
            <Icon
              name='filled-bell'
              filled
              className='text-cool-900'
              size={16}
            />

            <div className='absolute -right-1 -top-1 flex h-[0.875rem] w-[0.875rem] items-center justify-center rounded-full border border-cool-50 bg-danger-dark text-cool-50'></div>
          </>
        ) : (
          <Icon
            name='bell'
            className='pointer-events-none text-cool-900'
            size={16}
          />
        )}
      </div>
    </TooltipMenu>
  )
}
