import { Icon } from 'components/common/Icon'
import { Info } from 'components/common/Info'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import Link from 'next/link'
import { useCallback } from 'react'
import { useDrawer } from '../../contexts'

export type WaysToStep = {
  title: string
  text: string
  imgName: string
  info?: string
}

export type WaysToProps = {
  type: 'buy' | 'sell'
}

export const useWaysTo = () => {
  const { setIsOpen, setConfig } = useDrawer()

  const setWaysTo = useCallback(
    (type: 'sell' | 'buy') => {
      setIsOpen(true)
      setConfig({
        direction: 'right',
        hasSeparator: true,
        hasIcon: true,
        heading: <WaysToHeader type={type} />,
        body: <WaysToContent type={type} />,
      })
    },
    [setConfig, setIsOpen]
  )

  return setWaysTo
}

export const WaysToHeader = ({ type }: WaysToProps) => {
  const { t } = useTranslation('common')
  const { setIsOpen } = useDrawer()

  return (
    <div className='flex items-start justify-between'>
      <div>
        <h3 className='font-body text-lg font-medium'>
          <Trans
            i18nKey={`common:there_are_2_ways_to_${type}`}
            components={{
              comp: <span className='text-purple-500' />,
            }}
          />
        </h3>
        <h4 className='font-body text-sm font-normal text-cool-600'>
          {t('learn_more_on')}{' '}
          <Link
            href={`/how-it-works/for-${type === 'buy' ? 'buyers' : 'sellers'}`}
            className='text-secondary-default'
            onClick={() => setIsOpen(false)}
            target='_blank'
          >
            {type === 'buy' ? t('buyers_page') : t('sellers_page')}
          </Link>
        </h4>
      </div>
      <button type='button' onClick={() => setIsOpen(false)}>
        <Icon name='close' size={24} className='text-cool-600' />
      </button>
    </div>
  )
}

export const WaysToContent = ({ type }: WaysToProps) => {
  const { t } = useTranslation('common')

  const steps =
    type === 'buy'
      ? [
          {
            title: t('buy_now'),
            info: t('if_there_are_no_active_listings'),
            text: t('if_the_item_has_1_or_more_listings'),
            imgName: 'buy-now-hiw.png',
          },
          {
            title: t('place_bid'),
            text: t('or_you_can_place_bid'),
            imgName: 'hiw-bid.png',
          },
        ]
      : [
          {
            title: t('sell_now'),
            info: t('if_there_are_no_active_bids'),
            text: t('if_an_item_has_1_or_more_bids'),
            imgName: 'sell-now-hiw.png',
          },
          {
            title: t('list_your_item'),
            text: t('or_you_can_list'),
            imgName: 'list-hiw.png',
          },
        ]

  return (
    <div className='h-full overflow-y-auto bg-warm-50 px-6 py-5'>
      <ol className='flex flex-col space-y-5'>
        {steps.map(({ title, imgName, text, info }, index) => (
          <li
            key={index}
            className='flex flex-col space-y-3 rounded border border-cool-300  bg-cool-50 p-4'
          >
            <h5 className='text-lg font-medium'>
              {index + 1}. {title}
            </h5>
            {info && <Info>{info}</Info>}
            <p className='whitespace-pre-line text-sm text-cool-600'>{text}</p>
            <div className='flex items-center justify-center rounded bg-warm-50 p-3'>
              <div className='relative h-[126px] w-[172px]'>
                <Image
                  src={`/files/${imgName}`}
                  alt={title}
                  fill
                  quality={100}
                  className='object-cover object-center'
                  sizes='20vw'
                />
              </div>
            </div>
          </li>
        ))}
      </ol>
    </div>
  )
}
