import classNames from 'classnames'
import { Icon } from 'components/common/Icon'
import { useState } from 'react'

const placements = {
  top: {
    container: 'bottom-full right-1/2 translate-x-1/2 pb-3',
    tooltip:
      'before:top-full before:border-t-cool-50 before:left-1/2 before:-translate-x-1/2',
  },
  'top-left': {
    container: 'bottom-full right-0 pb-3',
    tooltip:
      'before:top-full before:border-t-cool-50 before:right-0 before:-translate-x-4',
  },
  'top-right': {
    container: 'bottom-full left-0 pb-3',
    tooltip:
      'before:top-full before:border-t-cool-50 before:left-0 before:translate-x-4',
  },
  bottom: {
    container: 'top-full right-1/2 translate-x-1/2 pt-3',
    tooltip:
      'before:bottom-full before:border-b-cool-50 before:left-1/2 before:-translate-x-1/2',
  },
  'bottom-left': {
    container: 'top-full right-0 pt-3',
    tooltip:
      'before:bottom-full before:border-b-cool-50 before:right-0 before:-translate-x-4',
  },
  'bottom-right': {
    container: 'top-full left-0 pt-3',
    tooltip:
      'before:bottom-full before:border-b-cool-50 before:left-0 before:translate-x-4',
  },
  left: {
    container: 'right-full top-1/2 pr-3 -translate-y-1/2',
    tooltip:
      'before:left-full before:border-l-cool-50 before:top-1/2 before:-translate-y-1/2',
  },
  'left-top': {
    container: 'right-full top-0 pr-3',
    tooltip:
      'before:left-full before:border-l-cool-50 before:top-0 before:translate-y-4',
  },
  'left-bottom': {
    container: 'right-full bottom-0 pr-3',
    tooltip:
      'before:left-full before:border-l-cool-50 before:bottom-0 before:-translate-y-4',
  },
  right: {
    container: 'left-full top-1/2 -translate-y-1/2 pl-3',
    tooltip:
      'before:right-full before:border-r-cool-50 before:top-1/2 before:-translate-y-1/2',
  },
  'right-top': {
    container: 'left-full top-0 pl-3',
    tooltip:
      'before:right-full before:border-r-cool-50 before:top-0 before:translate-y-4',
  },
  'right-bottom': {
    container: 'left-full top-1/2 -translate-y-1/2 pl-3',
    tooltip:
      'before:right-full before:border-r-cool-50 before:top-1/2 before:-translate-y-1/2',
  },
} as const

export type TooltipMenuProps = {
  children?: React.ReactNode
  className?: string
  content: React.ReactNode
  contentClassName?: string
  placement?: keyof typeof placements
  hasArrow?: boolean
}

export const TooltipMenu = ({
  children,
  className,
  content,
  contentClassName,
  placement = 'top',
  hasArrow = true,
}: TooltipMenuProps) => {
  const [isHovered, setIsHovered] = useState(false)

  if (!content) return <>{children}</>

  return (
    <div
      className={`group relative  ${className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children ? (
        children
      ) : (
        <Icon name='tooltip' className='text-cool-500' size={16} />
      )}
      <div
        className={classNames(
          'absolute z-30 scale-0 transition-all duration-200 ease-in-out group-hover:scale-100',
          placements[placement].container,
          isHovered && content ? 'block' : 'hidden'
        )}
      >
        <div
          className={`relative inline-block w-max rounded-2xl bg-cool-50 px-6 py-4 text-sm text-cool-700 shadow-[0_2px_20px_0_rgba(38,35,56,0.15)] before:absolute before:bottom-0 before:h-0 before:w-0 before:border-8 before:border-solid before:border-transparent  ${
            hasArrow ? 'before:block' : 'before:hidden'
          }  ${contentClassName} ${placements[placement].tooltip}`}
        >
          {isHovered && content}
        </div>
      </div>
    </div>
  )
}
