import classNames from 'classnames'
import { Icon } from 'components/common/Icon'
import { StaticIcon } from 'components/common/StaticIcon'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'
import {
  FocusEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  MouseEventHandler,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { tw } from '../../utils/strings'
const SearchBox = dynamic(
  () => import('react-instantsearch').then(m => m.SearchBox),
  { ssr: false }
)

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'pattern'> {
  size?: 'sm' | 'md' | 'lg'
  error?: boolean
  pill?: boolean
  caption?: boolean | React.ReactNode
  label?: string | React.ReactNode
  type:
    | 'algolia-search'
    | 'text'
    | 'email'
    | 'password'
    | 'number'
    | 'tel'
    | 'url'
    | 'search'
    | 'checkbox'
    | 'radio'
    | 'hidden'
    | 'reset'
  onClearError?: MouseEventHandler
  iconSize?: number
  iconClassName?: string
  inputClassName?: string
  labelClassName?: string
  iconPosition?: 'left' | 'right'
  placeholder?: string
  onEnterPress?: VoidFunction
  pattern?: string | null
  hiddenLabel?: boolean
  variant?: 'default' | 'gray-bg'
  captionPosition?: 'top' | 'bottom'
}

type Ref = HTMLInputElement | null

export const Input = forwardRef<Ref, Props>(
  (
    {
      id,
      children, // eslint-disable-line @typescript-eslint/no-unused-vars
      size = 'md',
      error,
      pill,
      caption = false,
      label,
      type,
      className,
      iconSize = 15,
      iconClassName,
      inputClassName,
      labelClassName,
      iconPosition = 'left',
      pattern,
      title,
      onClearError,
      onFocus,
      onBlur,
      onChange,
      onInvalid,
      value,
      placeholder,
      onEnterPress,
      onKeyDown,
      hiddenLabel = false,
      variant = 'default',
      captionPosition = 'bottom',
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation()
    const inputRef = useRef<Ref>(null)
    const [isError, setIsError] = useState(error)
    const [isViewPassword, setIsViewPassword] = useState(false)
    const [isFocus, setIsFocus] = useState(false)
    const [hasEnoughCharacters, setHasEnoughCharacters] = useState(false)
    const [hasEnoughNumbers, setHasEnoughNumbers] = useState(false)
    const isTypeSearch = type.includes('search')
    const isTypePassword = type === 'password'
    const hasIcon = isError || isTypePassword || isTypeSearch

    useImperativeHandle<Ref, Ref>(ref, () => inputRef.current, [])

    useEffect(() => {
      if (error) {
        setIsError(true)
      }
    }, [error])

    const checkValidity = (value: string) => {
      if (type === 'password') {
        if (value.length >= 8) {
          setHasEnoughCharacters(true)
        } else {
          setHasEnoughCharacters(false)
        }

        if (/\d/.test(value)) {
          setHasEnoughNumbers(true)
        } else {
          setHasEnoughNumbers(false)
        }
      }

      if (inputRef.current) {
        inputRef.current.setCustomValidity('')
      }
    }

    const getPattern = () => {
      if (pattern === null) return undefined

      if (pattern) {
        return pattern
      }

      if (type === 'password') {
        return '(?=.*\\d).{8,}'
      }

      if (type === 'email') {
        return '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$'
      }
    }

    const getTitle = () => {
      if (title === null) return undefined

      if (title) {
        return title
      }

      if (type === 'password') {
        return t('common:password_validation')
      }

      if (type === 'email') {
        return t('common:email_validation')
      }
    }

    const onInternalFocus = (event: FocusEvent<HTMLInputElement, Element>) => {
      setIsFocus(!isFocus)
      onFocus?.(event)
    }

    const onInternalKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(event)

      if (event.key === 'Enter') onEnterPress?.()
    }

    const styles = {
      base: tw`w-full flex items-center focus:outline-none disabled:bg-cool-400 disabled:text-cool-600 disabled:opacity-100 px-4
      ${
        type !== 'checkbox' &&
        type !== 'radio' &&
        'transition-colors duration-200'
      }
      ${
        isError
          ? tw`border-danger-dark hover:border-danger-dark focus:border-danger-dark disabled:border-danger-dark`
          : tw`border-cool-400 hover:border-cool-500 ${
              isTypeSearch ? 'focus:border-slate' : 'focus:border-cool-900'
            } disabled:border-cool-400 disabled:text-base disabled:text-cool-700`
      }
      ${value && isTypeSearch && 'border-slate'}
      ${pill ? 'rounded-full' : 'rounded'}
      ${
        hasIcon &&
        !isTypeSearch &&
        !isTypePassword &&
        !error &&
        iconPosition === 'left' &&
        tw`pl-9`
      }
     ${
       hasIcon &&
       !isTypeSearch &&
       !isTypePassword &&
       !error &&
       iconPosition === 'right' &&
       tw`pr-9`
     }
     ${hasIcon && isTypeSearch && iconPosition === 'left' && tw`pl-10`}
      ${hasIcon && isTypeSearch && iconPosition === 'right' && tw`pr-10`}
     `,
      default: classNames(
        'bg-cool-50 text-cool-900 placeholder-cool-500 border'
      ),
      'gray-bg': classNames('bg-warm-100 text-warm-900 placeholder-warm-500'),
      sm: tw`text-sm h-8`,
      md: tw`text-sm h-10`,
      lg: tw`text-base h-12`,
      searchIcon: classNames(
        `absolute top-1/2 -translate-y-1/2 cursor-pointer ${iconClassName} text-slate`,
        {
          'left-3': size === 'sm' && iconPosition === 'left',
          'left-4': size !== 'sm' && iconPosition === 'left',
        },
        {
          'right-3': size === 'sm' && iconPosition === 'right',
          'right-4': size !== 'sm' && iconPosition === 'right',
        }
      ),
    }

    const searchIconProps = {
      name: 'search',
      size: iconSize,
      onClick: () => {
        setIsViewPassword(!isViewPassword)
      },
    } as const

    const searchIcon = (
      <Icon
        name='search'
        size={iconSize}
        onClick={() => {
          setIsViewPassword(!isViewPassword)
        }}
      />
    )

    const captionElement = (
      <>
        {caption && (
          <>
            {typeof caption !== 'boolean' && (
              <p
                className={classNames(
                  'text-xs',
                  {
                    'text-danger-default': isError,
                    'text-cool-600': !isError,
                  },
                  captionPosition === 'bottom' ? 'mt-2' : 'mb-1'
                )}
              >
                {caption}
              </p>
            )}

            {typeof caption === 'boolean' && (
              <>
                {type === 'password' ? (
                  <p
                    className={classNames(
                      'text-xs text-cool-600',
                      captionPosition === 'bottom' ? 'mt-2' : 'mb-1'
                    )}
                  >
                    {t('common:must_include')}{' '}
                    <>
                      <span
                        className={classNames({
                          'text-danger-default':
                            isFocus && !hasEnoughCharacters,
                          'text-success-default':
                            isFocus && hasEnoughCharacters,
                          'text-cool-800': !isFocus && variant === 'gray-bg',
                        })}
                      >
                        {t('common:8+_characters')}
                      </span>
                      {' & '}
                      <span
                        className={classNames({
                          'text-danger-default': isFocus && !hasEnoughNumbers,
                          'text-success-default': isFocus && hasEnoughNumbers,
                          'text-cool-800': !isFocus && variant === 'gray-bg',
                        })}
                      >
                        {t('common:1_number')}
                      </span>
                    </>
                  </p>
                ) : (
                  <>
                    {title && (
                      <p
                        className={classNames(
                          'text-xs',
                          {
                            'text-danger-default': isError,
                            'text-cool-600': !isError,
                          },
                          captionPosition === 'bottom' ? 'mt-2' : 'mb-1'
                        )}
                      >
                        {getTitle()}
                      </p>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    )

    return (
      <div className={className}>
        {label && (
          <label
            className={classNames(
              'flex justify-between text-sm font-medium',
              labelClassName,
              hiddenLabel && 'sr-only',
              captionPosition === 'bottom' && 'mb-1'
            )}
            htmlFor={id}
          >
            {label}
          </label>
        )}

        {captionPosition === 'top' && captionElement}

        <div
          className={`relative w-full ${
            type !== 'checkbox' && type !== 'radio' && styles[size]
          }`}
        >
          {isError && (
            <Icon
              name='circle-actions-close'
              className={classNames(
                'absolute top-1/2 -translate-y-1/2 cursor-pointer text-chili-600',
                {
                  'right-3': size === 'sm',
                  'right-4': size !== 'sm',
                }
              )}
              size={iconSize}
              onClick={event => {
                if (inputRef.current) {
                  inputRef.current.value = ''
                }
                setIsError(false)
                onClearError?.(event)
              }}
            />
          )}

          {!isError && type === 'password' && (
            <button
              type='button'
              onClick={() => {
                setIsViewPassword(!isViewPassword)
              }}
              className={classNames(
                `absolute top-1/2 -translate-y-1/2 cursor-pointer ${iconClassName}`,
                {
                  'right-3': size === 'sm',
                  'right-4': size !== 'sm',
                }
              )}
            >
              <span className='sr-only'>
                {isViewPassword ? 'Hide password' : 'View password'}
              </span>

              <Icon
                name={isViewPassword ? 'hide' : 'view'}
                size={iconSize}
                className={classNames({
                  'text-warm-400': variant === 'gray-bg',
                })}
              />
            </button>
          )}

          {!isError && type === 'search' && (
            <StaticIcon {...searchIconProps} className={styles.searchIcon} />
          )}

          {type === 'algolia-search' && (
            <SearchBox
              id={id}
              classNames={{
                input: `${styles.base} ${styles[size]} ${inputClassName} ${styles[variant]}`,
                submit: styles.searchIcon,
                submitIcon: iconClassName,
              }}
              submitIconComponent={() => (isError ? null : searchIcon)}
              resetIconComponent={() => null}
              placeholder={placeholder}
              onFocus={onInternalFocus}
              onKeyDown={onInternalKeyDown}
            />
          )}

          {type !== 'algolia-search' && (
            <input
              {...props}
              id={id}
              ref={inputRef}
              type={isViewPassword ? 'text' : type}
              size={16}
              className={classNames(
                styles.base,
                styles[size],
                styles[variant],
                inputClassName
              )}
              onFocus={onInternalFocus}
              onBlur={event => {
                setIsFocus(!isFocus)
                onBlur?.(event)
              }}
              onChange={event => {
                checkValidity(event.target.value)
                onChange?.(event)
              }}
              onInvalid={event => {
                const title = getTitle()
                if (title) {
                  event.currentTarget.setCustomValidity(title)
                }
                onInvalid && onInvalid(event)
              }}
              pattern={getPattern()}
              title={getTitle()}
              value={value}
              placeholder={placeholder}
              onKeyDown={onInternalKeyDown}
            />
          )}
        </div>

        {captionPosition === 'bottom' && captionElement}
      </div>
    )
  }
)

Input.displayName = 'Input'
