import { Button } from 'components/common/Button'
import { Icon } from 'components/common/Icon'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { Suspense, lazy } from 'react'
import { titleCase } from 'utils/strings'
import { useAuth, useAuthModal, useDrawer } from '../../contexts'
import { TooltipMenu } from 'components/common/TooltipMenu'

const MyAccountPopupMenu = lazy(() =>
  import('components/common/MyAccountPopupMenu').then(m => ({
    default: m.MyAccountPopupMenu,
  }))
)

type Props = {
  isMobile?: boolean
}

export const AuthActions = ({ isMobile = false }: Props) => {
  const { t } = useTranslation()
  const { setIsOpen } = useDrawer()
  const { logout, isAuthenticated } = useAuth()
  const router = useRouter()
  const { setIsOpen: setIsAuthModalOpen, setState: setAuthModalState } =
    useAuthModal()

  return (
    <>
      {isAuthenticated && !isMobile && (
        <TooltipMenu
          hasArrow={false}
          contentClassName='mt-1 !p-4 !z-[999999]'
          placement='bottom-left'
          content={
            <Suspense>
              <MyAccountPopupMenu />
            </Suspense>
          }
        >
          <button type='button' className='flex items-center p-2'>
            <span className='sr-only'>{t('common:my_account')}</span>

            <Icon
              name='filled-user'
              filled
              className='text-primary-default'
              size={16}
            />
          </button>
        </TooltipMenu>
      )}

      {isAuthenticated && isMobile && (
        <Button
          pill
          style='solid-white'
          className='whitespace-nowrap'
          onClick={async () => {
            router.pathname.includes('/my-account') && router.push('/')
            await logout()
            setIsOpen(false)
          }}
          size='xl'
          data-e2e='log-out-button'
        >
          {t('common:log_out')}
        </Button>
      )}

      {!isAuthenticated && (
        <>
          <Button
            pill
            style='solid-white'
            className='ml-3 whitespace-nowrap lg:ml-0 lg:mr-3'
            onClick={() => {
              setIsOpen(false)
              setIsAuthModalOpen(true)
              setAuthModalState('login')
            }}
            size={isMobile ? 'xl' : 'md'}
            data-e2e='log-in-button'
          >
            {titleCase(t('common:log_in'))}
          </Button>

          <Button
            pill
            className='whitespace-nowrap'
            onClick={() => {
              setIsOpen(false)
              setIsAuthModalOpen(true)
              setAuthModalState('register')
            }}
            size={isMobile ? 'xl' : 'md'}
            style='solid-primary'
            data-e2e='sign-up-button'
          >
            {t('common:sign_up')}
          </Button>
        </>
      )}
    </>
  )
}
