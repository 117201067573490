import { NavMenu } from 'components/nav/NavMenu'

type Props = {
  className?: string
  actions?: React.ReactNode
}

export const MobileMenu = ({ actions, className }: Props) => {
  return (
    <div className={`h-full w-full overflow-auto bg-cool-150 ${className}`}>
      <div className='container flex min-h-full w-full flex-col  justify-between p-6 lg:rounded-xl'>
        <NavMenu vertical />

        {/* TODO: Align actions to the bottom */}
        {actions && (
          <div className='flex flex-row-reverse items-center justify-end'>
            {actions}
          </div>
        )}
      </div>
    </div>
  )
}
