import { Icon } from 'components/common/Icon'
import cx from 'classnames'
import { MouseEventHandler } from 'react'

const colors = {
  black: 'bg-cool-800 text-cool-150',
  green: 'bg-success-light text-cool-150',
  'light-green': 'bg-[#00B78F] text-cool-150',
  primary: 'bg-primary-default text-cool-150',
  red: 'bg-chili-800 text-cool-150',
  yellow: 'bg-warning-light text-cool-800 border border-warning-default',
  transparent:
    'bg-transparent text-cool-700 border border-solid border border-cool-400',
  'red-transparent':
    'bg-danger-light border border-opacity-25 border-danger-dark text-cool-800',
  none: '',
} as const

const sizes = {
  S: 'text-xs',
  M: 'text-sm',
}

interface Props {
  children?: React.ReactNode
  className?: string
  icon?: string
  iconFilled?: boolean
  color?: keyof typeof colors
  size?: keyof typeof sizes
  iconSize?: number
  iconPosition?: 'left' | 'right'
  iconClassName?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

export const Tag = ({
  children,
  className,
  icon = 'check',
  iconFilled = false,
  color = 'black',
  size = 'M',
  iconSize,
  iconPosition = 'left',
  iconClassName,
  onClick,
}: Props) => {
  return (
    <div
      className={cx(
        `inline-flex h-auto items-center justify-center rounded-full py-1
        ${colors[color]}
        ${sizes[size]}
        ${className}
      `,
        {
          'pl-2': iconPosition === 'left',
          'pr-3': iconPosition === 'left',
          'pr-2': iconPosition === 'right',
          'pl-3': iconPosition === 'right',
        }
      )}
      onClick={onClick}
    >
      {icon && iconPosition === 'left' && (
        <Icon
          name={icon}
          size={iconSize}
          filled={iconFilled}
          className={`mr-2 text-cool-150 ${iconClassName}`}
        />
      )}

      {children}

      {icon && iconPosition === 'right' && (
        <Icon
          name={icon}
          size={iconSize}
          filled={iconFilled}
          className={`ml-2 text-cool-150 ${iconClassName}`}
        />
      )}
    </div>
  )
}
