import { Notification, NotificationType } from 'api/notifications'
import cx from 'classnames'
import routes from 'constants/routes'
import { Translate } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import Link from 'next/link'
import { ARTWORK_IMG_PLACEHOLDER } from 'utils/image'
import { formatCurrency } from 'utils/strings'
import {
  getArtistUrl,
  getArtworkUrl,
  getArtworkUrlFromRoot,
  getBuyNowUrl,
  getEditAskUrl,
  getEditBidUrl,
  getOrderUrl,
  getSaleUrl,
  getSellNowUrl,
} from 'utils/urls'

const sizes = {
  sm: {
    title: 'text-xs',
    description: 'text-2xs',
    tag: 'text-2xs',
    image: 'max-h-[3.375rem] min-h-[3.375rem] min-w-[2.75rem] max-w-[2.75rem]',
  },
  default: {
    title: 'text-sm',
    description: 'text-xs',
    tag: 'text-xs',
    image:
      'max-h-[3.375rem] min-h-[3.375rem] min-w-[3.375rem] max-w-[3.375rem]',
  },
} as const

export type Props = {
  classNames?: {
    root?: string
    imageContainer?: string
    textContainer?: string
  }
  size?: keyof typeof sizes
} & Notification

const TypeToContentMap: Record<
  NotificationType,
  {
    title: string
    action?: string
    secondaryAction?: string
    description?: string
  }
> = {
  [NotificationType.ASK_EXPIRED]: {
    title: 'your_listing_expired',
    action: 'renew_listing',
    description: 'descriptions.price_artist_artwork',
  },
  [NotificationType.ASK_EXPIRED_1day]: {
    title: 'your_listing_expires_tomorrow',
    action: 'extend_listing',
    description: 'descriptions.price_artist_artwork',
  },
  [NotificationType.ASK_EXPIRED_7days]: {
    title: 'your_listing_expires_7_days',
    action: 'extend_listing',
    description: 'descriptions.price_artist_artwork',
  },
  [NotificationType.BID_EXPIRED]: {
    title: 'your_offer_expired',
    action: 'renew_offer',
    description: 'descriptions.price_artist_artwork',
  },
  [NotificationType.BID_EXPIRED_1day]: {
    title: 'your_offer_expires_tomorrow',
    action: 'extend_offer',
    description: 'descriptions.price_artist_artwork',
  },
  [NotificationType.BID_EXPIRED_7days]: {
    title: 'your_offer_expires_7_days',
    action: 'extend_offer',
    description: 'descriptions.price_artist_artwork',
  },
  [NotificationType.NEW_HIGHEST_BID_BUYER]: {
    title: 'new_highest_listing',
    action: 'update_listing',
    description: 'descriptions.price_artist_artwork',
  },
  [NotificationType.NEW_HIGHEST_BID_BUYER_FOLLOW]: {
    title: 'new_highest_offer',
    action: 'view',
    description: 'descriptions.price_artist_artwork',
  },
  [NotificationType.NEW_HIGHEST_BID_SELLER]: {
    title: 'new_highest_offer',
    action: 'update_listing',
    secondaryAction: 'sell_now',
    description: 'descriptions.price_artist_artwork',
  },
  [NotificationType.NEW_LOWEST_ASK_BUYER]: {
    title: 'new_lowest_listing',
    action: 'update_offer',
    secondaryAction: 'buy_now',
    description: 'descriptions.price_artist_artwork',
  },
  [NotificationType.NEW_LOWEST_ASK_SELLER]: {
    title: 'new_lowest_listing',
    action: 'update_listing',
    description: 'descriptions.price_artist_artwork',
  },
  [NotificationType.NEW_LOWEST_ASK_SELLER_FOLLOW]: {
    title: 'new_lowest_listing',
    action: 'view',
    description: 'descriptions.artist_name',
  },
  [NotificationType.NEW_SALE_FOLLOW_ARTIST]: {
    title: 'new_sale_followed_artist',
    description: 'descriptions.artwork_was_sold',
  },
  [NotificationType.NEW_SALE_SAVE_ARTWORK]: {
    title: 'new_sale_saved_artwork',
    description: 'descriptions.artwork_was_sold',
  },
  [NotificationType.ORDER_BY_ANOTHER_BUYER]: {
    title: 'missed_out',
    action: 'update_offer',
    description: 'descriptions.new_sale',
  },
  [NotificationType.ORDER_CONFIRMATION]: {
    title: 'order_confirmed',
    action: 'view_order_details',
    description: 'descriptions.artwork_artist',
  },
  [NotificationType.SALE_BY_ANOTHER_SELLER]: {
    title: 'missed_out',
    action: 'update_listing',
    description: 'descriptions.new_sale',
  },
  [NotificationType.SALE_CONFIRMATION]: {
    title: 'your_item_sold',
    action: 'view_sale_details',
    description: 'descriptions.artwork_was_sold',
  },
  [NotificationType.SALE_CONFIRMATION_PASS_AUTH]: {
    title: 'your_item_sold',
    action: 'upload_auth_docs',
    description: 'descriptions.artwork_was_sold',
  },
}

const getNotificationContent = (
  { type, groupingCount, artwork, context }: Props,
  t: Translate
) => {
  const content = TypeToContentMap[type] ?? {}
  let { title, action } = content
  const { secondaryAction, description } = content

  const isNotificationGrouped = groupingCount && groupingCount > 1

  const artworkName = artwork?.name || ''
  const artistName =
    artwork?.artists?.map(artist => artist.name).join(' x ') || ''
  const price = formatCurrency(context?.price || 0, {
    minimumFractionDigits: 2,
  })

  if (
    type === NotificationType.NEW_LOWEST_ASK_SELLER_FOLLOW &&
    isNotificationGrouped
  ) {
    title = 'x_new_lowest_listings'
    action = 'view_works'
  }

  return {
    title: t(title, isNotificationGrouped ? { groupingCount } : undefined),
    action: action ? t(action) : undefined,
    secondaryAction: secondaryAction ? t(secondaryAction) : undefined,
    description: description
      ? t(description, { artworkName, artistName, price })
      : undefined,
  }
}

const getActionsBehavior = (
  props: Props
): { primary: string; secondary?: string } => {
  const { context, type, artwork, groupingCount } = props

  const defaultReturn = { primary: '#' }

  if (!context || !type || !artwork) return defaultReturn

  const { artists = [], name: artworkName = '', id: artworkId } = artwork
  const { askId, bidId, orderId, dhlInsurance } = context

  switch (type) {
    // upload auth docs
    case NotificationType.SALE_CONFIRMATION_PASS_AUTH: {
      return {
        primary: getArtworkUrlFromRoot(
          'ask-or-sell/submit-documents',
          artists,
          artworkName,
          artworkId
        ),
      }
    }

    // view order details
    case NotificationType.ORDER_CONFIRMATION: {
      if (!askId || !bidId || !orderId) return defaultReturn
      return { primary: getOrderUrl(artworkId, askId, bidId, orderId) }
    }

    // view sale details
    case NotificationType.SALE_CONFIRMATION: {
      if (!askId || !bidId || !orderId) return defaultReturn
      return { primary: getSaleUrl(artworkId, askId, bidId, orderId) }
    }

    // update listing
    case NotificationType.SALE_BY_ANOTHER_SELLER:
    case NotificationType.NEW_HIGHEST_BID_SELLER:
    case NotificationType.NEW_LOWEST_ASK_SELLER: {
      return {
        primary: getEditAskUrl(artists, artworkName, artworkId),
        secondary: getSellNowUrl(artists, artworkName, artworkId, dhlInsurance),
      }
    }

    // update offer
    case NotificationType.ORDER_BY_ANOTHER_BUYER:
    case NotificationType.NEW_HIGHEST_BID_BUYER:
    case NotificationType.NEW_LOWEST_ASK_BUYER: {
      return {
        primary: getEditBidUrl(artists, artworkName, artworkId),
        secondary: getBuyNowUrl(artists, artworkName, artworkId),
      }
    }

    // view bid/view bid
    case NotificationType.NEW_HIGHEST_BID_BUYER_FOLLOW:
    case NotificationType.NEW_LOWEST_ASK_SELLER_FOLLOW: {
      const artworkUrl = getArtworkUrl(artists, artworkName, artworkId)

      if (groupingCount && groupingCount > 0) {
        const artist = artists[0]
        if (!artist) return { primary: artworkUrl }
        return {
          primary: getArtistUrl(artist.name, artist.id) + '?sort=Lowest+Ask',
        }
      }

      return { primary: artworkUrl }
    }

    // extend offer
    case NotificationType.BID_EXPIRED_1day:
    case NotificationType.BID_EXPIRED_7days: {
      return { primary: routes.myAccount.buying }
    }

    // extend listing
    case NotificationType.ASK_EXPIRED_1day:
    case NotificationType.ASK_EXPIRED_7days: {
      return { primary: routes.myAccount.selling }
    }

    // renew offer
    case NotificationType.BID_EXPIRED: {
      return { primary: `${routes.myAccount.buying}?tab=expired_offers` }
    }

    // renew listing
    case NotificationType.ASK_EXPIRED: {
      return { primary: `${routes.myAccount.selling}?tab=expired_listings` }
    }

    default:
      return { primary: '#' }
  }
}

export const NotificationComponent = (props: Props) => {
  const { t } = useTranslation('notifications')

  const { type, viewed, classNames, size = 'default', artwork } = props

  const { title, description } = getNotificationContent(props, t)

  const sizeStyles = sizes[size]

  const artworkImage = artwork?.images?.[0]?.url

  const isDanger = type === NotificationType.SALE_CONFIRMATION_PASS_AUTH

  return (
    <div
      className={cx(
        'flex overflow-hidden',
        classNames?.root,
        isDanger && '!border-t-[3px] !border-danger-dark'
      )}
    >
      <div
        className={cx(
          'flex items-center justify-center',
          classNames?.imageContainer
        )}
      >
        <Image
          src={artworkImage || ARTWORK_IMG_PLACEHOLDER}
          alt={artwork?.name || 'placeholder'}
          width={44}
          height={54}
          className={cx('object-contain', sizeStyles.image)}
        />
      </div>

      <div className={cx('w-full', classNames?.textContainer)}>
        <div className='flex w-full items-center justify-between'>
          <h6
            className={cx(
              'font-body font-medium tracking-normal',
              sizeStyles.title
            )}
          >
            {title}
          </h6>

          {!viewed && (
            <div className='rounded-2.5xl bg-primary-light px-2.5 py-0.5'>
              <p
                className={cx(
                  'font-medium text-primary-default',
                  sizeStyles.tag
                )}
              >
                {t('new')}
              </p>
            </div>
          )}
        </div>

        {description && (
          <p
            className={cx(
              'line-clamp-2 w-full whitespace-pre-line text-xs text-cool-600',
              sizeStyles.description
            )}
          >
            {description}
          </p>
        )}

        <NotificationAction {...props} />
      </div>
    </div>
  )
}

const NotificationAction = (props: Props) => {
  const { t } = useTranslation('notifications')

  const { type } = props

  const { action, secondaryAction } = getNotificationContent(props, t)
  const { primary: onPrimaryAction, secondary: onSecondaryAction } =
    getActionsBehavior(props)

  if (!action && !secondaryAction) return null

  const isDanger = type === NotificationType.SALE_CONFIRMATION_PASS_AUTH

  return (
    <div className='mt-2 space-x-5'>
      {action && onPrimaryAction && (
        <Link
          href={onPrimaryAction}
          className={cx(
            'text-xs underline',
            isDanger ? 'text-danger-dark' : 'text-secondary-default'
          )}
        >
          {action}
        </Link>
      )}

      {secondaryAction && onSecondaryAction && (
        <Link
          href={onSecondaryAction}
          className='text-xs text-secondary-default underline'
        >
          {secondaryAction}
        </Link>
      )}
    </div>
  )
}
