import { Icon } from 'components/common/Icon'

const themes = {
  info: {
    className: 'border-secondary-default bg-secondary-default',
    iconName: 'filled-alert-info',
    iconClassName: 'text-secondary-default',
  },
  success: {
    className: 'bg-success-default border-success-default',
    iconName: 'filled-success',
    iconClassName: 'text-success-default',
  },
  error: {
    className: 'bg-chili-800 border-chili-800',
    iconName: 'filled-close',
    iconClassName: 'text-danger-dark',
  },
  purple: {
    className: 'bg-primary-default border-primary-default !bg-opacity-100',
    iconName: 'filled-alert-info',
    iconClassName: 'text-cool-50',
  },
  'purple-transparent': {
    className:
      'bg-purple-100 border-purple-100 !bg-opacity-100 text-purple-900',
    iconName: 'filled-alert-info',
    iconClassName: 'text-purple-700',
  },
  'primary-light': {
    className: 'bg-primary-light !bg-opacity-100 border-none',
    iconName: 'filled-alert-info',
    iconClassName: 'text-primary-default',
  },
} as const

type Props = {
  children?: React.ReactNode
  theme?: keyof typeof themes
  className?: string
  iconSize?: number
  iconName?: string
  iconClassName?: string
}

export const Info = ({
  children,
  theme = 'info',
  className,
  iconSize = 16,
  iconClassName,
}: Props) => {
  return (
    <div
      className={`flex  items-start rounded border border-opacity-10 bg-opacity-5 px-4 py-3 text-sm text-cool-800  ${className} ${themes[theme].className}`}
    >
      <Icon
        name={themes[theme].iconName}
        className={`mr-2 mt-[2px] ${iconClassName} ${themes[theme].iconClassName}`}
        size={iconSize}
        filled
      />
      <div>{children}</div>
    </div>
  )
}
